import EventBus from "../event-bus";
import axios from "axios";
import Vue from "vue";
import "../components/Configure";
import moment from "moment";
import CryptoJS from "crypto-js";
const APIkey =
  "ca3fec0bc53190c90863e0f41579e27cbc98a57a97c909455df7db816f4ae4bd";
export default {
  components: {
    EventBus,
  },
  data() {
    return {
      encriptkey: "goforARide",
      servers: this.$store.state.servers,
      status: this.$store.state.status,
      //      statusFieldName: "none",
      //      statusFieldID: "none",
    };
  },
  methods: {

    handleError(msg, error = null, url = "") {
      var title = msg;
      var errors = [];
      var errorMsg = "";

      // 

      if (error !== null) {
        //  
        if (error["message"] != undefined) {
          errorMsg += this.pp(error.message) + "  \n";
        }

        if (error["response"] !== undefined) {
          if (error.response["data"] !== undefined) {
            if (typeof error.response["data"] === "string")
              errors[0] = error.response.data;
            else errors = error.response.data;
            // could be  more tehn 1 line of error messages
            for (var i = 0; i < errors.length; ++i) {
              errorMsg += errors[i].substring(1, 340) + "\n";

              /*              if (errors[i].search("password") > -1) throw "login";
              if (errors[i].search("password") > -1) alert("Tell Jay, at throw login message");
              */
            }
          } else {
            errorMsg += error.response.statusText + " \n";
          }
        }

        if (error.message.search("500") > -1)
          errorMsg = "This was probably caused by a syntax error in Python. Check the Python server log for more details.";

        if (error["config"] !== undefined) {
          if (url !== "") errorMsg += "\nURL: \n" + decodeURI(url).substring(1, 340);
        }
      }

      //      EventBus.$emit("appError", [title, errorMsg.substring(1, 340)]);
      EventBus.$emit("appError", [title, errorMsg])
      EventBus.$emit("wait", false);
    },

    //
    // pretty print an object
    //
    pp(object) {
      return JSON.stringify(object, 4, "- ");
    },

    //
    // match regarless of case
    //
    fuzzyMatch(matchThis, againstThis) {
      if (againstThis.toLowerCase().search(matchThis.toLowerCase()) > -1) {
        return true;
      } else {
        return false;
      }
    },

    getStatusColor(custom) {
      //      console.log(this.pp(this.status.id));
      if (typeof custom !== "string") {
        var id = custom[this.status.id][0];
      } else id = custom;
      var row = this.status.choices.find(function (e) {
        return e.id === id;
      });
      if (row !== undefined) return row.color;
      for (var i = 0; i < this.status.choices.length; i++) {
        row = this.status.choices[i];
        if (this.fuzzyMatch(custom, row.name)) return row.color;
      }
      this.handleError(
        "getStatusColor: couldn't find color match for: " + custom
      );
      return "error";
    },

    getStatusID(name) {
      if (name === "ALL" || name === "NEW YEAR") return "all";

      const choices = this.$store.state.status.choices;
      for (var i = 0; i < choices.length; i++) {
        var y = choices[i];
        var match = this.fuzzyMatch(name, y.name);
        if (match === true) {
          return choices[i].id;
        }
      }
      this.handleError(
        "getStatusID: counld't find status name: [" + name + "]"
      );
      return -1;
    },

    getStatusName(id) {
      if (id === "ALL" || id === "NEW YEAR") return id;
      var statusChoices = this.$store.state.status.choices;
      for (var i = 0; i < statusChoices.length; i++) {
        var status = statusChoices[i];
        var match = this.fuzzyMatch(id, status.name);
        if (match === true) {
          return statusChoices[i].name;
        }
      }
      this.handleError("getStatusName: counld't find statusID: " + id);
    },

    getPaceID(name) {
      if (name === "ALL" || name === "NEW YEAR") return name;

      var customDefs = this.$store.state.customDefs;
      for (var i = 0; i < customDefs.length; i++) {
        var def = customDefs[i];
        var matched = this.fuzzyMatch(name, def.name);
        if (matched === true) {
          return customDefs[i].id;
        }
      }
      return "error";
    },
    /*
    lookup values for field definitions and store them for easier access
    */

    mapTeamupColor(teamupColor = 0) {
      //      console.log(statusName);
      if (teamupColor !== 0) {
        var color = this.$colors.find(function (e) {
          return e.id === String(teamupColor);
        });
        if (color !== undefined) return color.color;
      }

      return;
    },
    //
    // determine what the correct yar is
    // really just needed for testing 
    getYear(whichYear) {
      var today = new Date();
      var month = today.getMonth();
      var year = today.getFullYear();
      if (this.$store.state.config === null) {
        this.handleError("configuration data not set in getYear");
        return "None";
      }
      var calLimit = this.$store.state.config.features.calendar_limit;
      // fi calLImt is less than 9, it is testing and only has q1 year of back kata
      if (calLimit < 9) year += 1;

      if (whichYear == "previous") return year - 1;
      else if (whichYear == "current") return year;
      else this.handleError("error in getYear");
    },

    // copy as value, not as reference 
    copy(old) {
      var newObj = [];
      if (old === undefined) return undefined;
      newObj = JSON.parse(JSON.stringify(old));
      return newObj;
    },


    displayCustom(fieldID, custom) {
      try {
        if (custom === undefined) return;
        var row = this.$store.state.customDefs.find(function (e) {
          return e.id === fieldID;
        });
        if (row === undefined) {
          console.log("field ID is not defined in customDefs!");
          return;
        }
        // multiple predifended results 
        if (row.type === "choices") {
          var choices = row.type_data.options;

          if (choices === undefined) {
            this.handleError(
              "couldn'find choices for " + fieldID + " " + this.pp(custom)
            );
            return -1;
          }
          if (fieldID in custom) {
            var choice = choices.find(function (e) {
              return e.id === custom[fieldID][0];
            });
            if (choice === undefined) {
              this.handleError(
                "couldn'find choice option for " +
                fieldID +
                " " +
                this.pp(custom)
              );
              return -1;
            }

            return {
              id: fieldID,
              name: row.name,
              value: choice.name,
              color: this.mapTeamupColor(choice.color),
            };
          }
        } else {
          // user defined value
          return { id: fieldID, name: row.name, value: custom[fieldID] };
        }
      } catch (error) {
        this.handleError(error);
      }
    },

    /**** 
        async addRide(ride) {
          var url = this.servers["calendar"] + "events";
          //  await axios({
          await axios({
            method: "POST",
            url: url,
            data: ride,
            headers: this.$store.state.teamupHeaders,
          }).catch((error) => {
            this.handleError("Error adding ride" + this.pp(ride), error, url, ride);
          });
    
          EventBus.$emit("wait", false);
        },
    
    *****/

    async updateRide(ride, updatedRide) {
      if (this.$route.name.search("rideReview") > -1) {
        var server = "https://api.teamup.com/" + this.$noPasswordCal + "/";

        //        var server = this.servers["calendar"]; //nopassword
        //        this.servers["calendar"] = this.servers["noPassword"];

        this.$store.state.teamupHeaders = {
          "Teamup-Token": APIkey,
          "Content-type": "application/json; charset=UTF-7",
        };
      }
      var url = server + "events/" + ride.id;
      var resp = {};
      await axios({
        method: "PUT",
        url: url,
        data: updatedRide,
        headers: this.$store.state.teamupHeaders,
      })
        .then((response) => {
          //          console.log( "RESP " + response.data.event.start_dt + " " +  response.data.event.rrule);

          resp = response.data.event;
          ride.version = resp.version;
          ride.update_dt = resp.update_dt;
          ride.custom = resp.custom;
          ride.ristart_dt = resp.ristart_dt;
          ride.rsstart_dt = resp.rsstart_dt;
          ride.rrule = resp.rrule;
          //r.custom = val.custom;
        })

        .catch((error) => {
          this.handleError("Error updating ride", error, url, ride);
          alert("Error updating ride" + error + url + this.pp(ride));
          EventBus.$emit("wait", false);
        });
    },

    deleteRide(rideList, ride) {
      var cnt = 0;

      var url = this.servers["calendar"] + "events/" + ride.id;
      ride.redit = "all";
      /**? */
      var index = 0;
      var size = rideList.length;

      for (index = 0; index < size; ++index) {
        if (rideList[index].id === ride.id) break;
        else continue;
      }
      if (index === rideList.length) {
        return -1;
      }
      ride["redit"] = "all";
      //    console.log(ride.id + ride.series_id, ride.ristart_dt);
      axios({
        method: "DELETE",
        url: url,
        data: ride,
        headers: this.$store.state.teamupHeaders,
      })
        .then(() => {
          cnt += 1;
          console.log("success deleting " + cnt + "\n");
          rideList.splice(index, 1);
          //return rideList;
        })
        .catch((error) => {
          this.handleError("Error deleting ride", error, url, ride);
          EventBus.$emit("wait", false);
          //          resolve();
        });
      //      await this.sleep(100).then(() => {
      //        //       then do something
      //      });
    },

    // Useage: sleep (milliseconds)
    // await this.sleep(1000).then(() => {
    //       then do something
    //     });
    // 
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    //
    // prevent browsers (Safari) from cacheing data and missing updated data
    noCache(path) {
      var rid = moment().unix();
      this.$router.push({ path: path, query: { rid: rid } });
    },

    checkPassword(password, savePWD) {
      var enCrypted = password;
      if (savePWD !== "encrypted") {
        enCrypted = CryptoJS.AES.encrypt(password, this.encriptkey).toString();
        if (savePWD) localStorage.setItem("calendar", enCrypted);
      }
      sessionStorage.setItem("calendar", enCrypted);
      return this.getCalData("force");
    },

    // get calendar data if "force" is passed in
    // go to teamup to get new data. Otherwise uses 
    // cache
    async getCalData(mode = "cache") {

      // if config == null there's no cached data
      if (this.$store.state.config !== null && mode !== "force") {
        console.log("USING Cache for Configuraton.");
        return "ok";
      }
      console.log(
        "GOING to teamup for Configuration calendar is " + this.$calendar);
      try {
        await this.getServerNames();
        await this.getTeamUpConfig();

        // Once password has been veried set requresAuth to false 

        if (String(this.$route.path).search("RideReview") === -1) {


          sessionStorage.setItem("requiresAuth", false);
        }
        await this.getSubCals();
        return "ok";
      } catch (error) {
        if (error === "login") return "login";
        return "error";
      }
    },

    async getServerNames() {
      if (this.status.id !== undefined)
        if (this.status.id !== "") {
          console.log("USING Cache for Server Names");
          return "ok";
        }

      this.servers["html"] = "https://" + location.host + "/";
      // this.servers["html"] = "https://" + location.host + "/";

      if (location.host.search("localhost") > -1)
        this.servers["html"] = "http://debian/";
      this.servers["python"] = this.servers["html"] + "/python/";

      /*       if (process.env.VUE_APP_MODE === undefined)
        if (process.env.VUE_APP_PYTHON_SERVER !== undefined) {
          this.servers["html"] =
            "http://" + process.env.VUE_APP_PYTHON_SERVER + "/";
          this.servers["python"] = this.servers["html"] + "python/";
        }
    */ //      this.servers["python"] = "foo";
      const url =
        this.servers["python"] + "getCalendarNames?filename=calendarNames.cfg";

      await axios({
        method: "GET",
        url: url,
        headers: { "Content-type": "application/json" },
        timeout: 9000,
      })
        .then((names) => {
          ///          var results = names.data;
          var key = "calendar"
          if (this.$route.name === "rideReview") {
            key = "modifier"
            this.$store.state.teamupHeaders = {
              "Teamup-Token": APIkey,
              "Content-type": "application/json",

            }
          }

          ///          var keys = Object.keys(results);

          var results = Object.entries(names.data);
          for (var i = 0; i < results.length; ++i) {
            if (results[i][0] === "modifier")
              Vue.prototype.$noPasswordCal = results[i][1];
            if (results[i][0] === key) {
              var value = results[i][1];
              this.servers["calendar"] =
                "https://api.teamup.com/" + value + "/";
              Vue.prototype.$calendar = value;
            }
            this.$store.state.servers = this.servers;
          }
          //          console.log("servers " + this.pp(this.servers));
          return "ok";
          /* 
                      for (var i = 0; i < keys.length; ++i) {
                      var value = results[keys[i]];
                      this.servers[keys[i]] = value;
                      if (keys[i] === "calendar") {
                        this.servers["calendar"] =
                          "https://api.teamup.com/" + value + "/";
                        Vue.prototype.$calendar = value;
          
                      }
          
                      // This allows ride leaders to update
                      // the ride status in the calendar withou
                      // having a password 
                      if (keys[i] === "modifier") {
                        if (this.$route.name === "rideReview")
                          this.servers["calendar"] =
                            "https://api.teamup.com/" + value + "/";
          
                        //    this.servers["noPassword"] =
                        //      "https://api.teamup.com/" + value + "/";
                        Vue.prototype.$noPasswordCal = value;
                      }
                    }
                    if (this.$calendar === undefined) {
                      this.handleError(
                        "Couldn't find admin calendar in calendar.cfg: " +
                        this.pp(this.servers)
                      );
                    }
          
                    // Sanity checkss
                    if (this.$noPasswordCal === undefined) {
                      this.this.handleError(
                        "Coundn't find modifier calendar in calendar.cfg"
                      );
                    }
                     this.$store.state.servers = this.servers;
          
                    if (this.$route.name === "rideReview") {
                      //            this.servers["calendar"] = this.servers["noPassword"];
                      //console.log("servers modified" + this.pp(this.servers));
                      this.$store.state.teamupHeaders = {
                        "Teamup-Token": APIkey,
                        "Content-type": "application/json",
                      };
                    }
          console.log("servers " + this.pp(this.servers));
          return "ok";
           */
          //end of geCalendarNames

        })
        .catch((error) => {
          this.handleError(
            "getServerNames: Error connecting  to " +
            this.servers["python"] +
            " to open calendar.cfg.",
            error,
            url
          );
          throw "error";
        });
    },


    async getTeamUpConfig() {
      if (String(this.$route.path).search("RideReview") === -1) {
        /*
                if (String(this.$route.path).search("RideReview") > -1) {
                 this.servers["calendar"] = this.servers["noPassword"];
                this.$store.state.teamupHeaders = {
                  "Teamup-Token": APIkey,
                  "Content-type": "application/json",
                };
               } else  
        {*/
        var encrypted = sessionStorage.getItem("calendar");

        //      var encrypted = sessionStorage.getItem("calendar");
        if (encrypted === undefined) throw "login";

        const bytes = CryptoJS.AES.decrypt(encrypted, this.encriptkey);
        var password = bytes.toString(CryptoJS.enc.Utf8);
        //      var password = encrypted;
        if (password === "") {
          sessionStorage.setItem("calendarPWD", "");
          throw "login";
        }
        sessionStorage.setItem("calendarPWD", "ok");

        this.$store.state.teamupHeaders = {
          "Teamup-Token": APIkey,
          "Content-type": "application/json",
          "Teamup-Password": password,
        };
      }

      //      console.log(
      //        "teamup headers: " + this.pp(this.//$store.state.teamupHeaders)
      //      );
      var url = this.servers["calendar"] + "configuration";
      await axios({
        method: "GET",
        url: url,
        headers: this.$store.state.teamupHeaders,
      })
        .then((conf) => {
          this.$store.state.config = conf.data.configuration;
          this.$store.state.customDefs =
            conf.data.configuration.fields.definitions;
          const customDefs = this.$store.state.customDefs;
          var title = conf.data.configuration.identity.title;
          this.year = this.getYear("current");

          EventBus.$emit("title", title);
          for (var i = 0; i < customDefs.length; i++) {
            if (this.fuzzyMatch("status", customDefs[i].name)) {
              if (this.fuzzyMatch("leader", customDefs[i].name)) continue;
              var newRow = [];
              var row = this.copy(customDefs[i]);
              if (row["name"] == "") continue;
              newRow = {
                name: row["name"],
                id: row["id"],
                type: row["type"],
              };
              if (row.type === "choices") {
                var choices = row.type_data.options;
                for (var j = 0; j < choices.length; ++j)
                  choices[j].color = this.mapTeamupColor(choices[j].color);
              }
              this.$store.state.status.id = newRow.id;
              this.$store.state.status.name = newRow.name;
              this.$store.state.status.choices = choices;
              this.status = this.$store.state.status;
              break;
            }
          }

          sessionStorage.setItem("requiresAuth", false);
          //          ret = "ok";
        })
        .catch((error) => {
          if (error.response.status === 403) {
            throw "login";
            //            ret = "login";
          } else {
            this.handleError(
              "Error connecting  to " +
              this.servers["calendar"] +
              " to get Teamup configuration data in getTeamUpConfig",
              error,
              url
            );
            this.handleError("Error in  getTeamUpConfig: ", error, url);
            throw "error";
          }
        });
    },

    async getSubCals() {
      var url = this.servers["calendar"] + "subcalendars";
      await axios({
        method: "GET",
        url: url,
        headers: this.$store.state.teamupHeaders,
      })
        .then((subCal) => {
          const subCalendars = subCal.data.subcalendars;
          this.$store.state.subCalendars = subCalendars;
          return "ok";
        })
        .catch((error) => {
          this.handleError("Error getting SubCalendar Data ", error, url);
          throw "error";
        });
    },

    async getRides(
      rideLeader,
      rideStatus,
      mode = "cache",
      start = "",
      end = ""
    ) {
      //      var ridesList = [];
      var rides = [];
      var rideLeaders = [];
      if (
        start === "" &&
        //        rideLeader ===3" "all" &&
        rideStatus === "all" &&
        mode !== "force"
      )
        if ((await this.getCalData()) === "error") return "error";
      //      console.log("STATUS" + this.pp(this.$store.state.status));
      var results = [];
      var results2 = [];
      if (this.$store.state.ridesList.length > 0 && mode === "cache") {
        console.log("USING cache for Rides");
        EventBus.$emit("rideLeaders", this.$store.state.rideLeaders);

        if (rideLeader !== "all") {
          results = this.$store.state.ridesList.filter(function (e) {
            // filter function
            return e.who === rideLeader;
          });
          //            console.log(this.pp(rides));
          //            const ups = this.copy(rides);
        }
        if (rideStatus !== "all")
          results2 = rides.filter(function (e) {
            // filter function
            return e.custom[this.status.id] === rideStatus;
          });
        //
        if (results2.length > 0) return results2;
        else if (results.length > 0) return results;
        else return this.$store.state.ridesList;
      }
      console.log("GOING to teamup for rides.");
      //    var rideList = [];
      if (start === "" || end === "") {
        start = "1/1/" + this.getYear("current");
        end = "12/31/" + this.getYear("current");
      } //          if (ret === -1) return;
      console.log("requesting: " + start + " to: " + end);
      //console.log("getting rides rideLeader is: " + rideLeader + " status is:" + rideStatus);

      //
      // get  field definitions
      //        console.log("URL " + url2);
      var url2 =
        this.servers["calendar"] + //nopassword
        "events?startDate=" +
        start +
        "&endDate=" +
        end;
      var response2 = await axios({
        method: "GET",
        url: url2,
        headers: this.$store.state.teamupHeaders,
      }).catch((error) => {
        this.handleError("Error getting ride Data ", error, url2);
        return "error";
      });
      //  .then((response2) => {
      const skip = [];
      const pendID = this.getStatusID("pend");
      const changeID = this.getStatusID("change");
      const statusID = this.$store.state.status.id;
      const paceID = this.getPaceID("pace");
      var response = response2.data.events;

      // rides.sort((a, b) => (a.who > b.who ? 1 : -1));
      /*
    response = response.sort((a, b) => {
    var t1 = moment(a.start_dt);
    var t2 = moment(b.start_dt);
    return t1 > t2 ? 1 : -1;
    });
    for (i = 0; i < response.length; i++) {
    }
    */
      response.sort((a, b) => a.series_id - b.series_id); // ? 1 : -1));
      var oldID = response[0].series_id;
      var i = 0;
      //      console.log(
      //        response[i].start_dt + " " + response[i].title + " " + response[i].who
      //);
      //var results = [];
      for (i = 0; i < response.length; i++) {
        if (oldID === response[i].series_id && response[i].series_id !== null) {
          /*           console.log(
            "skip" +
              moment(response[i].start_dt).format("M/DD/YYYY") +
              " " +
              response[i].series_id +
              " " +
              response[i].title +
              " " +
              response[i].who
          );
         */
          continue;
        } else oldID = response[i].series_id;
        results.push(response[i]);
        /* console.log(      moment(response[i].start_dt).format("M/DD/YYYY") + " " + response[i].series_id + " " + response[i].title + " " +  response[i].who );
         */
      }

      //      results.sort((a, b) => a.who - b.who); // b - a for reverse sort
      //       console.log("heading id " + keys[i].id);
      //       console.log("heading id " + keys[i].id);
      //       console.log("heading id " + keys[i].id);
      var keys = Object.values(this.$store.state.status.choices);
      for (i = 0; i < keys.length; i++) {
        rideLeaders[i] = keys[i].id;
        rideLeaders[keys[i].id] = [];
        //       console.log("heading id " + keys[i].id);
      }
      rideLeaders[keys.length] = "all";
      rideLeaders["all"] = [];
      var rrulePerYear = {};

      for (i = 0; i < results.length; i++) {
        // Ignore week<ly rides

        var ride = results[i];
        ride.Date = moment(ride.start_dt).format("ddd M/DD/YYYY h:mm");
        // DEBUG mode === "WEEKLY" is for debugging
        /*
        if (mode === "WEEKLY") {
          ride.start_dt = moment(ride.start_dt).format("MM/DD/YYYY");
          ride.end_dt = moment(ride.end_dt).format("MM/DD/YYYY");
          ride.ristart_dt = moment(ride.ristart_dt).format("MM/DD/YYYY");
          ride.rsstart_dt = moment(ride.rsstart_dt).format("MM/DD/YYYY");
        }
        */
        if ("custom" in ride === true) {
          var displayAttr = this.displayCustom(statusID, ride.custom);
          ride.displayStatus = displayAttr.value;
        }

        //
        // DEBUG rrulePerYear is for debugging
        /*
        var p = moment(ride.start_dt);
        var yr = p.format("YYYY");
        var tm = ride.rrule.split(";", 1);
        if (rrulePerYear[yr + tm[0]] === undefined) {
          rrulePerYear[yr + tm[0]] = 0;
        }
        rrulePerYear[yr + tm[0]] += 1;
   
   
        // DEBUG mode === "WEEKLY" is for debugging
        if (mode === "WEEKLY") {
          if (ride.rrule.search("WEEKLY") !== -1) {
            //console.log(ride.Date + " " + ride.title + " " + ride.who + " " + ride.pace);
            rides.push(ride);
          }
          continue;
        }
        */
        // console.log(ride.Date + " " + ride.title + " " + ride.who + " " + ride.pace);

        // filter out weekly rides
        if (ride.rrule.search("WEEKLY") > 0) {
          // ride.reason = "is " + "WEEKLY" + " repeat";
          ride.reason = "are weekly";
          skip.push(ride);
          continue;
        }

        // ignore if no ride leader
        //        if (ride.who === "") {
        //          ride.reason = "no ride leader set in ride";
        //        skip.push(ride);
        //      continue;
        //    }
        // create a list of all ride leaders
        const subCalendars = this.$store.state.subCalendars;
        const subCalendar = subCalendars.find(function (e) {
          return e.id === ride.subcalendar_id;
        });

        if (subCalendar === undefined) {
          this.handleError(
            "Couldn't find subcalendar_id " + ride.subcalendar_id + " in " + this.pp(ride));
          return "error";
        }
        if (this.fuzzyMatch("meet", subCalendar.name)) {
          ride.reason = "are meeting";
          skip.push(ride);

          continue;
        }

        if (rideLeader !== "all") {
          if (this.fuzzyMatch(rideLeader, ride.who) === false) {
            ride.reason = "are no rideLeader match";
            skip.push(ride);
            continue;
          }
        }

        // if custom fields exists for this ride
        if ("custom" in ride === true) {
          if (ride.who == undefined) {
            this.handleError("Couldn't find ride leader name (who) in " + this.pp(ride));
          }


          // make a list of ride leaders by each statusID 
          if (rideLeaders[ride.custom[statusID]].indexOf(ride.who) === -1)
            rideLeaders[ride.custom[statusID]].push(ride.who);

          // add ride leader to the list of all ride leaders
          if (rideLeaders["all"].indexOf(ride.who) === -1)
            rideLeaders["all"].push(ride.who);

          if (paceID != -1) {
            var pace = this.displayCustom(paceID, ride.custom);
            if (pace) ride["pace"] = pace["value"];
          } else ride.pace = "No Pace Data";
          //              console.log(this.pp(ride.custom));

          if (ride["custom"][statusID][0] != rideStatus)
            if (rideStatus !== "all") {
              ride.reason = "are not " + rideStatus;
              skip.push(ride);
              continue;
            }
        }
        ride.dialog = false;
        rides.push(ride);

        //                 total += 1;
      }

      /*      rideLeaders.all.sort();
      rideLeaders.pending.sort();
    rideLeaders.change.sort();
    */
      rideLeaders["all"].sort();
      rideLeaders[pendID].sort();
      rideLeaders[changeID].sort();

      //          this.pendingRideLeaders.sort();
      if (rideLeaders.length > 1) this.$store.state.rideLeaders = rideLeaders;

      EventBus.$emit("rideLeaders", rideLeaders);
      // console.log(
      skip.sort((a, b) => (a.reason > b.reason ? 1 : -1));
      console.log("skipping rides because:");
      for (var cnt = 0; cnt < skip.length; cnt++) {
        var rd = skip[cnt];
        console.log(rd.reason);
      }

      // DEBUG print the rrule per year count
      /* foe debugging
      keys = Object.keys(rrulePerYear);
      var x = keys.length;
      for (var j = 0; j < x; ++j)
        console.log(keys[j] + ": " + rrulePerYear[keys[j]]);
      */
      console.log("Returning " + rides.length + " rides.");
      if (rides.length === 0) {
        this.handleError("Missing ride data for " + start + " thru " + end);
      } else {
        this.$store.state.ridesList = rides;
      }
      return this.$store.state.ridesList;
    },
  },
};
