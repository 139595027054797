<template>
  <div id="RideReview">
    <v-card :class="this.$bgColor" width="100%" class="head">
      <v-row justify="center" align="center">
        <v-img src="https://ebcrides.org/images/cal/EBClogo-animated.gif" class="bottom" width="100">
        </v-img>
        <!--         <article>
          <h3 class="display-2 font-weight-bold mb-1 logo">
            {{ year }} Rides for {{ rideLeader }}
          </h3>
        </article>

 -->
        <h3 class="logo display-2 font-weight-bold mb-1 ">

          &nbsp; {{ year }} Rides for {{ rideLeader }} &nbsp;
        </h3>
        <v-img src="https://ebcrides.org/images/cal/EBClogo-animated.gif" class="bottom" width="100">
        </v-img>
        <!--         <h3 class="display-2 font-weight-bold mb-1 logo">
          {{ year }} Rides for {{ rideLeader }}
        </h3>
 -->
      </v-row>
    </v-card>
    <v-card :class="this.$bgColor">

      <v-col>
        <v-row>
          <!--       <v-layout align-start wrap col justify-space-around>
                  v-model="rideList"
                     bg:
                fg: "#57647f",
                header: "#576490",
         -->
          <h2>{{ msg }}</h2>
          <v-dialog persistent :value="dialog" width="400" transition="dialog-top-transition">
            <v-card :color="cardColors.bg" dark>
              <v-col>
                <v-form ref="form" @keyup.native.enter="">
                  <v-toolbar :color="cardColors.header" dark>
                    Describe your changes in the box below.
                    <br />
                  </v-toolbar>
                  <v-textarea v-model="rideChgInfo" background-color="#3b4474" auto-grow outlined clearable
                    hint="press SEND when you're done."></v-textarea>
                  <v-card-actions>
                    <v-btn dark color="green darken-1" @click="emailInfo(itm, rideChgInfo)">
                      Send
                    </v-btn>
                    <v-btn dark color=" red darken-1" @click="dialog = false">
                      Cancel
                    </v-btn>
                  </v-card-actions>
                  <rideCard :item="itm" :status-name="rideStatus" />
                </v-form>
              </v-col>
            </v-card>
          </v-dialog>
        </v-row>
        <ConfirmPopUp ref="confirm" top></ConfirmPopUp>
        <v-row justify="space-around">

          <v-card v-for="item in rideList" :key="item.id" dark class="rideCard" width="300"
            :color="getStatusColor(item.custom)" elevation="5">
            <!--  
                          color: {{ getStatusColor(item.custom) }} {{ item.custom }}

                          checkConfirm(item.custom, 'change')
                      ? changeColor
                      : checkConfirm(item.custom, 'confirm')

                      ? confirmColor
                      : pendColor
                  "
                          v-model="item.custom"
         -->
            <v-card :color="cardColors.bg" scrollable>
              <v-col>
                <v-row justify="space-around">
                  <v-btn :color="confirmColor" class="btn2" :disabled="checkConfirm(item.custom, 'confirm')"
                    @click="setStatus('confirm', item)">
                    Confirm Ride
                  </v-btn>
                  <v-btn class="btn2" :color="changeColor" :disabled="checkConfirm(item.custom, 'change')"
                    @click="openChange(item)">
                    Change Ride
                  </v-btn>
                </v-row>
                <v-row justify="space-around">
                  <v-menu>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn class="btn" color="primary" dark v-bind="attrs" small v-on="on">
                        Add this ride to your Calendar
                      </v-btn>
                    </template>
                    <v-list elevation="3" color="primary" dark>
                      <v-list-item v-for="(calType, index) in calTypes" :key="index" @click="addToCal(calType, item)">
                        <v-list-item-title tiny>
                          {{ calType }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <!--
                        <v-menu>
                          v-model="cal"
                          @change="addToCal(cal)"
                          label="Add to your Calendar"
                          :items="calTypes"
                        ></v-menu>
         -->
                </v-row>
              </v-col>
            </v-card>
            <rideCard :item="item" :status-name="statusName" />
          </v-card>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>
<script>
// @ is an alias to /src
import axios from "axios";
import moment from "moment";
import RideCard from "../components/RideCard.vue";
import ConfirmPopUp from "../components/Confirm.vue";
import EventBus from "../event-bus";
import mixins from "../components/mixins";

// import buttontip from "../components/ButtonTip.vue";
// const server = "http://192.168.1.22/";
export default {
  name: "RideReview",
  components: {
    RideCard,
    ConfirmPopUp,
  },
  mixins: [mixins],
  props: {
    rideStatus: {
      type: String,
      default: "all",
    },
    rideLeader: {
      type: String,
      default: "JoAnn Davis",
    },
  },
  data() {
    return {
      xOffset: 0,
      confirmID: "",
      changeID: "",
      changeColor: "",
      confirmColor: "",
      cal: "",
      year: "",
      calTypes: ["Google", "iCal", "Windows 11", "Outlook", "Yahoo", "AOL"],
      dialog2: false,
      dialog: false,
      // modifyInfo: "",
      msg: "",
      rideChgInfo: "",
      changePending: "",
      statusName: "",
      itm: "",
      cardColors: {
        bg: "grey  black--text ",
        header: "#1D2A57",
      },
      rideList: [],
      filled: true,
      leaderList: [],
      fieldDefs: [],
      //      statusValues: [],
      customFields: [],
      snackBarMsg: "Sending...",
      snackBar: false,
      snackBar2: false,
    };
  },
  created() {
    //      var rideLeader = "Joanne Davis";

    this.init();
  },
  methods: {
    async addToCal(calType, ride) {
      var p = new moment(ride.start_dt);
      var sdate = p.format("YYYYMMDD") + "T";

      if (navigator.userAgent.match(/iPhone/) !== null | navigator.userAgent.match(/iPad/) !== null) {
        await this.$refs.confirm
          .open("IOS error: Can't add to your calendar", "Please use a laptop or computer instead.", {
            agreeButtonText: "Okay",
            oneButton: true,
          });


        return;
      }
      sdate += p.format("hhmmss");
      p = new moment(ride.end_dt);
      var edate = p.format("YYYYMMDD") + "T";
      edate += p.format("hhmmss");
      var url;
      var win = window.open(url, "_blank");
      if (calType === "Google") {
        url = "https://calendar.google.com/calendar/render?action=TEMPLATE";
        url += "&dates=" + sdate + "%2F" + edate;
        url += "&text=" + encodeURIComponent(ride["title"]);
        url += "&location=" + encodeURIComponent(ride["location"]);
        url += "&details=" + encodeURIComponent(ride["notes"]) + "&sf=True";
      } else if (calType === "iCal" || calType === "Windows 11") {
        url =
          "https://teamup.com/" +
          this.$noPasswordCal +
          "/events/" +
          ride["id"];
        if (!navigator.userAgent.match(/iPhone/))
          url += ".ics";
        else
          url += ".ics";
        //        if (navigator.userAgent.match(/iPhone/i)) {
        win.location = url;
        this.sleep(5000).then(() => {
          win.close();
        });
        return;
      } else if (calType === "Yahoo" || calType === "AOL") {
        if (calType === "Yahoo") url = "https://calendar.yahoo.com/?desc=";
        else url = "https://calendar.aol.com/?desc=";
        url += encodeURIComponent(ride["notes"]);
        url += "&dur=&et=" + edate + "&st=" + sdate;
        url += "&in_loc=" + encodeURIComponent(ride["location"]);
        url += "&title=" + encodeURIComponent(ride["title"]) + "&v=60";
      } else if (calType === "Outlook") {
        url = "https://outlook.of                             fice.com/calendar/0/deeplink/compose?body=";
        url += encodeURIComponent(ride["notes"]);
        url += "&startdt=" + sdate + "&enddt=" + edate;
        url += "&location=" + encodeURIComponent(ride["location"]) + "&path=";
        url += "&subject=" + encodeURIComponent(ride["title"]);
        encodeURIComponent;
      }
      win.location = url;
    },
    //      this.snackBar2 = true;
    /*
            if (calType === 'iCal') {
            url = "https://teamup.com/" + calendar + "/events/" + ride['id'] + ".ics";
            myfile = requests.get(url, allow_redirects=True)
            file = open(webDir + "public/event.ics", "wb")
        #    file.write("hello")
            file.write(myfile.content)
            file.close()
     
            iCal = " <a href='" + webServer + "public/event.ics' download"
            win11 = iCal + " class='btn1'>Windows 11</a>&nbsp;&nbsp;"
            iCal += " class='btn1'>iCal</a>&nbsp;&nbsp;"
        */
    openWin(ride) {
      var win = window.open("about:blank");
      var url = this.servers["calendar"] + "/events/" + ride; //    oalert("oen");
      //  this.teamup = url;
      win.location = url;
    },

    openChange(item) {
      this.itm = item;
      this.dialog = true;
    },
    emailInfo(ride, desc) {
      //      var templateString = this.$refs.rideCard(ride, this.statusFieldName);
      this.$refs.form.reset();
      this.setStatus("change", ride);
      var msg = "<p><h3 >" + ride.Date + " " + ride.title + "</h3></p>";
      msg +=
        ride.who +
        " has requested the following changes for  this ride.  <br />";
      desc = desc.replace(/\n/g, "<br />");
      var url =
        this.servers["python"] +
        "editRide" +
        "?cal=" +
        this.$noPasswordCalendar +
        "&id=" +
        ride.id;

      /*
    var btn =
      "<p><a target='edit' class='btn' href='" +
      url +

      "'>Edit the Ride</a>";
    btn += "<style> .btn {  background-color: #154c81;  color: white;  padding: 10px 20px;  text-decoration: none;}"
    btn += ".btn:hover {  background-color: #88aed1;} "
    btn += " .desc {  background: #FFE082; color:black; width:600 } </style>";
      */
      //                " .desc {  background: #FFE082; color:black;  padding: 10px 24px; } <style>";
      //            btn += " html { background-color: #eee; }</style>";
      //updateYear("2021");
      var url2 =
        this.servers["python"] +
        "/rideChangeMsg?" +
        "rideLeader=" +
        // "rideLeader=" +
        ride.who +
        "&msg=" +
        encodeURIComponent(
          msg + "<p readonly class='desc'>" + desc + "</p></br>"
        );

      axios({
        method: "GET",
        url: url2,
      })
        .then(() => {
          console.log("URL" + this.pp(url));
          this.snackBar = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.handleError(
            "Error Sending Message", error, url);
          this.snackBar = false;
          this.dialog = false;
          desc = "";
          EventBus.$emit("wait", false);
        });
    },

    setStatus(newStatus, ride) {
      var localRide = this.copy(ride)
      var sid = this.$store.state.status.id;
      this.statusName = this.$store.state.status.name;
      //      ride.redit = "all";
      localRide.custom[sid][0] = this.getStatusID(newStatus);
      localRide.redit = "all";
      this.updateRide(ride, localRide);
      // console.log("setStatus " + this.pp(ride));

      //      ride.id = newr.id;
      //ride.custom = newr.custom;
      this.dialog = false;

      //      updateYear("202
    },
    checkConfirm(custom, field) {
      //var id = custom[this.$store.state.statusFieldID][0];
      if (field === "confirm") {
        if (custom[this.statusID][0] === this.confirmID) return true;
      } else if (field === "change") {
        if (custom[this.statusID][0] === this.changeID) return true;
      }
      return false;

      //      var id = custom[this.$store.state.status.id][0];
      //      var confirm = this.getStatusID("confirm");

      //      var change = this.getStatusID("change");
      //      if (id === change && field === "confirm") {
      //        return false;
      //    }
      //  if ((id === confirm || id === change) && field === "confirm") {
      //    return true;
      //      } else if (id === change && field === "change") {
      //  }
      //      this.year = this.getYear("current");NNNN
      //      if (id === change && field === "change") {
      //        return true;
      //p      }
      //      return false;
    },

    async init() {
      EventBus.$emit("wait", true);

      //      alert(this.rideStatus + this.rideLeader);
      var x = await this.getCalData();
      this.year = this.getYear("current");

      console.log("ride review " + x);
      this.statusID = this.status.id;
      this.changeID = this.getStatusID("change");
      this.confirmID = this.getStatusID("confirm");
      this.confirmColor = this.getStatusColor("confirm");
      this.changeColor = this.getStatusColor("change");

      this.getRides(this.rideLeader, this.rideStatus).then((resp) => {
        this.rideList = resp;
        if (this.rideList.length === 0) {
          this.msg = "No rides found";
          return 0;
        }
        this.rideList.sort((a, b) => (a.start_dt > b.start_dt ? 1 : -1));
        //          this.statusName = this.statusFieldName;
        EventBus.$emit("wait", false);
      });
      //      this.getRides("all", "all").then(() => { });
      EventBus.$emit("showMenu", false);

    },
  },
};
</script>
<style scoped>
.btn {
  margin-top: 10px;
  margin-bottom: 3px;
}

.btn2 {
  margin-top: 2px;
}


.rideCard {
  padding: 8px;
  margin: 1px;
}


.bottom {
  max-width: 70px;
  margin-top: 10px;
}

.head {
  margin-bottom: 15px;
}

.logo {
  margin-top: 12px;
  margin-left: 5px;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,
    Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 18px;
  line-height: 1.45;
}

body {
  margin: 0;
}
</style>
